<template>
  <div class="howtoplay">
    <v-row dense>
      <v-col align="center">
        <h1>How To Play</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>

        <h3>Login/Sign-Up</h3>
        <p>Login or sign-up to start playing.  The cards for Moneyline, Spread, and Total will appear in their respective slots for each team in our Dashboard.</p>

        <h3>Your Wallet</h3>
        <p>When you sign-up you will receive $1,000 in non-real funds in your wallet to place wagers on our platform.  This is for demonstrative purposes only.  You can place unlimited bets in the beta version, as long as you <b>do not exceed $1,000 as a wager</b>.  These funds have no actual value, and they cannot be replenished by the player or be cashed-out or removed for actual funds or monies. You can view your wallet on the home page and main header.</p>

        <h3>The Dashboard</h3>
        <p>The platform will display the cards for the player to create their poker hands.  Players can choose from multiple sports and schedules.  The betting markets supported are moneyline, spread, and total (over/under).</p>

        <h3>Select Cards</h3>
        <p>Select cards from the dashboard to build your poker hand.  Click on the card and select Yes at the “Add this to your hand?” prompt.  If the card is grayed out that means it is disabled as you have already added it to your hand.  You may only select one type of card as the 52-card deck rule is in place to avoid duplicate card selections.</p>

        <h3>Make Poker Hands</h3>
        <p>Your selected cards will display in the Hand Builder tool.  As you select your card, the correlated poker hand will display.  Select three cards for a 3-Card Poker hand rank.  Select five cards for a 5-Card Poker hand rank.  See all hand rankings and payouts below.</p>

        <h3>Enter Bet and Total Winnings</h3>
        <p>When you are finished creating your desired 3-Card Poker or 5-Card Poker hand, place a bet to determine your total winnings.  The total winnings will update as your change your hand rank but will be final once you have clicked the Checkout button.  <b>In the beta version, you cannot place a bet more than $1,000.</b></p>

        <h3>Checkout and Place Bet</h3>
        <p>When you are ready to commit your desired poker hand and wager with the displayed winnings, click on the Checkout button.  The player will be taken to a checkout page to fully commit the wager.  To commit the wager, click the Place Bet button. Once complete, the player's commited bet will now be available in the Bets section of the website as an active bet.</p>

        <h3>Bet Slip Outcome</h3>
        <p>In the beta version there will be no bet slip outcome features due to not utilzing live data.  For client integrations, this feature will be available.  Once the commited bet is active, it will be monitored real-time for status.  Once completed, a win/loss will be determined and the funds will move in the player's wallet accordingly.</p>

        <h3>Hand Rank Payouts</h3>
        <p>Payouts will be according to hand rank which will be below:</p>
        <game-hand-rank-keys></game-hand-rank-keys>

        <br>

        <h3>How we assign cards</h3>
        <p>We assign value cards of a traditional fifty-two (52) card deck using our proprietary algorithm.   The numbered values will be 2,3,4,5,6,7,8,9, and 10.  The face cards will be Jack, Queen, King, and Ace cards.  The general rule is the higher the underdog, the higher the assigned card and the higher the favorite the lower the assigned card.  However, card assignments may vary due to various factors and bonus events as determined.</p>

        <h3>How we assign suits</h3>
        <p>The game will assign the traditional playing card suits of Hearts, Clubs, Spades, and Diamonds.  We assign suits using our proprietary algorithm.  Card suit assignments may vary due to various factors and bonus events as determined.</p>

      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row>

    </v-row>

  </div>
</template>

<script>

  export default {
    name: "HowToPlay.vue",
    data() {
      return {
        window: 0,
        howtos:[
          {title: 'Slide #1', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'},
          {title: 'Slide #2', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'},
          {title: 'Slide #3', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'},
          {title: 'Slide #4', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'},
        ],
        terms:[
          {name: "Action", define: "Having a wager on a game."},
          {name: "ATS (\"against the [point] spread\")", define: "If a team is 5-2 ATS, it means it has a 5-2 record against the point spread, or more commonly referred to simply as the \"spread.\""},
          {name: "Backdoor cover", define: "When a team scores points at the end of a game to cover the spread unexpectedly."},
          {name: "Bad beat", define: "Losing a bet you should have won. It's especially used when the betting result is decided late in the game to change the side that covers the spread. Also used in poker, such as when a player way ahead in the expected win percentage loses on the river (last card)."},
          {name: "Beard", define: "Someone who places a wager for another person (aka \"runner\")."},
          {name: "Book", define: "Short for sportsbook or bookmaker; person or establishment that takes bets from customers."},
          {name: "Bookie", define: "A person who accepts bets illegally and charges vig."},
          {name: "Buying points", define: "Some bookies or sportsbooks will allow customers to alter the set line and then adjust odds. For example, a bettor might decide he wants to have his team as a 3-point underdog instead of the set line of 2.5. He has then \"bought\" half a point, and the odds of his bet will be changed."},
          {name: "Chalk", define: "The favorite in the game. People said to be \"chalk\" bettors typically bet the favorite."},
          {name: "Circle game", define: "A game for which the betting limits are lowered, usually because of injuries and/or weather."},
          {name: "Closing line", define: "The final line before the game or event begins."},
          {name: "Consensus pick", define: "Derived from data accumulated from a variety of sportsbooks in PickCenter. The pick, and its percentage, provides insight as to what side the public is taking in a game."},
          {name: "Cover", define: "The betting result on a point-spread wager. For a favorite to cover, it has to win by more than the spread; an underdog covers by winning outright or losing by less than the spread."},
          {name: "Dime", define: "Jargon for a $1,000 bet. If you bet \"three dimes,\" that means a $3,000 wager."},
          {name: "Dog", define: "Short for underdog."},
          {name: "Dollar", define: "Jargon for a $100 bet. Usually used with bookies; if you bet \"five dollars,\" that means a $500 wager."},
          {name: "Edge", define: "An advantage. Sports bettors might feel they have an edge on a book if they think its lines aren't accurate."},
          {name: "Even money", define: "Odds that are considered 50-50. You put up $1 to win $1."},
          {name: "Exotic", define: "Any wager other than a straight bet or parlay; can also be called a \"prop\" or \"proposition wager.\""},
          {name: "Favorite", define: "The expected straight-up winner in a game or event. Depending on the sport, the favorite will lay either odds or points. For example, in a football game, if a team is a 2.5-point favorite, it will have to win by three points or more to be an ATS winner."},
          {name: "Fixed", define: "A participant in a particular game who alters the result of that game or match to a completely or partially predetermined result. The participant did not play honestly or fairly because of an undue outside influence."},
          {name: "Futures bet", define: "A long-term wager that typically relates to a team's season-long success. Common futures bets include betting a team to win a championship at the outset of a season, or betting whether the team will win or lose more games than a set line at the start of the season."},
          {name: "Halftime bet", define: "A bet made after the first half ended and before the second half begins (football and basketball primarily). The oddsmaker generally starts with half of the game side/total and adjusts based on what happened in the first half."},
          {name: "Handicapper", define: "A person trying to predict the winners of an event."},
          {name: "Handle", define: "The amount of money taken by a book on an event or the total amount of money wagered."},
          {name: "Hedging", define: "Betting the opposing side of your original bet, to either ensure some profit or minimize potential loss. This is typically done with futures bets, but can also be done on individual games with halftime bets or in-game wagering."},
          {name: "High roller", define: "A high-stakes gambler."},
          {name: "Hook", define: "A half-point. If a team is a 7.5-point favorite, it is said to be \"laying seven and a hook.\""},
          {name: "In-game wagering", define: "A service offered by books in which bettors can place multiple bets in real time, as the game is occurring."},
          {name: "Juice", define: "The commission the bookie or bookmaker takes. Standard is 10 percent. Also called the \"vig/vigorish.\""},
          {name: "Layoff", define: "Money bet by a sportsbook with another sportsbook or bookmaker to reduce that book's liability."},
          {name: "Limit", define: "The maximum bet taken by a book. If a book has a $10,000 limit, it'll take that bet but the book will then decide whether it's going to adjust the line before the bettor can bet again."},
          {name: "Lock", define: "A guaranteed win in the eyes of the person who made the wager."},
          {name: "Middle", define: "When a line moves, a bettor can try to \"middle\" a wager and win both sides with minimal risk. Suppose a bettor bets one team as a 2.5-point favorite, then the line moves to 3.5 points. She can then bet the opposite team at 3.5 and hope the favorite wins by three points. She would then win both sides of the bet."},
          {name: "Money line (noun), money-line (modifier)", define: "A bet in which your team only needs to win. The point spread is replaced by odds."},
          {name: "Mush", define: "A bettor or gambler who is considered to be bad luck."},
          {name: "Nickel", define: "Jargon for a $500 bet. Usually used with bookies; if you bet \"a nickel,\" that means a $500 wager."},
          {name: "Oddsmaker (also linemaker)", define: "The person who sets the odds. Some people use it synonymous with \"bookmaker\" and often the same person will perform the role at a given book, but it can be separate if the oddsmaker is just setting the lines for the people who will eventually book the bets."},
          {name: "Off the board", define: "When a book or bookie has taken a bet down and is no longer accepting action or wagers on the game. This can happen if there is a late injury or some uncertainty regarding who will be participating."},
          {name: "Over/under", define: "A term that can be used to describe the total combined points in a game (the Ravens-Steelers over/under is 40 points) or the number of games a team will win in a season (the Broncos' over/under win total is 11.5). Also used in prop bets."},
          {name: "Parlay", define: "A wager in which multiple teams are bet, either against the spread or on the money line. For the wager to win (or pay out), all of them must cover/win. The more teams you bet, the greater the odds."},
          {name: "Pick 'em", define: "A game with no favorite or underdog. The point spread is zero, and the winner of the game is also the spread winner."},
          {name: 'Poker', define: 'A card game played by two or more people who bet on the value of the hands dealt to them. A player wins the pool either by having the highest combination at the showdown or by forcing all opponents to concede without a showing of the hand, sometimes by means of bluff.'},
          {name: "Point spread (or just \"spread\")", define: "The number of points by which the supposed better team is favored over the underdog."},
          {name: "Proposition (or prop) bet", define: "A special or exotic wager that's not normally on the betting board, such as which team will score first or how many yards a player will gain. Sometimes called a \"game within a game.\" These are especially popular on major events, with the Super Bowl being the ultimate prop betting event."},
          {name: "Push", define: "When a result lands on the betting number and all wagers are refunded. For example, a 3-point favorite wins by exactly three points. Return on investment (ROI): In PickCenter, ROI is the amount (according to numberFire) that a bettor should expect to get back on a spread pick."},
          {name: "Runner", define: "Someone who makes bets for another person (aka \"beard\")."},
          {name: "Sharp", define: "A professional, sophisticated sports bettor."},
          {name: 'Sports betting', define: 'Is the activity of predicting sports results and placing a wager on the outcome.'},
          {name: "Spread", define: "Short for point spread."},
          {name: "Square", define: "A casual gambler. Someone who typically isn't using sophisticated reasoning to make a wager."},
          {name: "Steam", define: "When a line is moving unusually fast. It can be a result of a group or syndicate of bettors all getting their bets in at the same time. It can also occur when a respected handicapper gives a bet his followers all jump on, or based on people reacting to news such as an injury or weather conditions."},
          {name: "Straight up", define: "The expected outright winner of the money line in an event or game, not contingent on the point spread."},
          {name: "Teaser", define: "Betting multiple teams and adjusting the point spread in all the games in the bettor's favor. All games have to be picked correctly to win the wager."},
          {name: "Total", define: "The perceived expected point, run or goal total in a game. For example, in a football game, if the total is 41 points, bettors can bet \"over\" or \"under\" on that perceived total."},
          {name: "Tout (service)", define: "a person (or group of people) who either sells or gives away picks on games or events."},
          {name: "Underdog", define: "The team that is expected to lose straight up. You can either bet that the team will lose by less than the predicted amount (ATS), or get better than even-money odds that it will win the game outright. For example, if a team is a 2-1 underdog, you can bet $100 that the team will win. If it wins, you win $200 plus receive your original $100 wager back."},
          {name: "Vig/vigorish", define: "The commission the bookie or bookmaker takes; also called the \"juice.\" Standard is 10 percent."},
          {name: "Wager", define: "A bet."},
          {name: "Welch", define: "To not pay off a losing bet."},
          {name: "Wiseguy", define: "A professional bettor. Another term for a \"sharp.\""},
        ]
      };
    },
    methods: {

    },
  };
</script>

<style lang="scss" scoped>
  .howtoplay{
    .v-carousel{
      width: calc(100% - 40px);
      margin: 20px;
      border: solid 1px #ccc;
      .v-window-item{
        .v-sheet{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 40px 80px;
        }
      }
    }
  }
</style>
